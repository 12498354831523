<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-8-14 15:17:59 -->
<!-- @Last Modified by: wangwuhui -->
<!-- @Last Modified time: 2024-01-30 16:53:32 -->

<template>
    <div
        id="unsubscribeReson"
        class="unsubscribeReson"
    >
        <div
            ref="btnw"
            class="content-wrapper"
        >
            <div class="your-reson">
                {{ getI18n('yourReson') }}
            </div>
            <div class="reson-box">
                <div
                    v-for="(item, index) in reasonList"
                    :key="index"
                    class="reason-item"
                    @click="item.checked = !item.checked"
                >
                    <img :src="item.checked ? selected : notSelected">
                    <span>{{ item.label }}</span>
                </div>
            </div>
            <div class="text-wrap">
                <textarea
                    ref="note"
                    v-model="note"
                    class="note"
                    maxlength="3000"
                    :placeholder="getI18n('detailReson')"
                    @focus="onKeyBoardCahnge('focus')"
                />
            </div>
        </div>
        <div class="confirm-wrapper">
            <div
                class="btn"
                :class="{'btn-disabled': !checkedList.length}"
                @click="handleReason"
            >
                {{ getI18n('apply') }}
            </div>
        </div>
    </div>
</template>

<script>
import ua from 'ssr-common/util/webview/ua-parsed';

import prefetch from 'easycash/common/mixin/server-prefetch';
import User from 'easycash/common/resource/user';

import maxRem from 'olacred/common/mixin/max-rem';

import notSelected from './img/notSelected.svg';
import selected from './img/selected.svg';

export default {
    name: 'UnsubscribeReason',

    title() {
        return this.getI18n('title');
    },

    mixins: [prefetch('unsubscribeReason'), maxRem],

    data() {
        return {
            selected,
            notSelected,
            originHeight: '',
            clientHeight: '',
            reasonList: [],
            note: '',
            isScroll: false
        };
    },

    computed: {
        checkedList() {
            return this.reasonList?.filter(item => item.checked)?.map(it => it.value);
        }
    },

    watch: {
        'unsubscribeReason.reasonList': {
            handler(val) {
                this.reasonList = val?.map((item, index) => ({
                    checked: index === 0,
                    ...item
                })) || [];
            },
            deep: true,
            immediate: true
        }
    },

    mounted() {
        this.originHeight = document.documentElement.clientHeight || document.body.clientHeight;
        this.clientHeight = document.getElementById('unsubscribeReson')?.clientHeight;
        this.onKeyBoardCahnge();
        window.addEventListener('resize', this.onKeyBoardCahnge);
        this.$nextTick(() => {
            const sel = document.querySelector('.content-wrapper');
            this.isScroll = sel.scrollHeight > sel.clientHeight;
        });
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.onKeyBoardCahnge);
    },

    methods: {
        getI18n(text, params) {
            return this.$t(`webview.unsubscribe.${text}`, params);
        },

        onKeyBoardCahnge(type) {
            this.$nextTick(() => {
                if (ua.isIOS) {
                    const scrollTop = this.$refs.note?.offsetTop - 100;
                    if (type === 'focus') document.documentElement.scrollTop = scrollTop;
                } else {
                    const resizeHeight = document.documentElement.clientHeight || document.body.clientHeight;
                    const reasonId = document.getElementById('unsubscribeReson');
                    if (resizeHeight < this.originHeight) {
                        if (this.clientHeight) {
                            if (reasonId) {
                                reasonId.style.height = `${this.clientHeight}px`;
                            }

                            const scrollTop = this.$refs.note?.offsetTop;
                            document.documentElement.scrollTop = scrollTop;
                        }
                    } else if (reasonId) {
                        reasonId.style.height = '100vh';
                    }
                }
            });
        },

        async initFetch({header}) {
            const {data: {body}} = await this.requestHelper(User.getListDeleteReason({headers: header, defaultValue: []}));

            return {reasonList: body};
        },

        handleReason() {
            if (!this.checkedList?.length) return;
            const {$route: {query: {mobileNumber}}} = this;
            window.localStorage.setItem('reasonList', JSON.stringify({
                mobileNumber,
                reasons: this.checkedList,
                note: this.note
            }));
            this.$router.push({
                name: 'unsubscribeOtp',
                query: {mobileNumber}
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@font-face {
    font-family: "HelveticaBold";
    src: url("~olacred/common/style/font-face/Helvetica-Bold.otf");
}

@font-face {
    font-family: "Helvetica";
    src: url("~olacred/common/style/font-face/Helvetica.ttf");
}

.unsubscribeReson {
    padding: 0;
    display: flex;
    height: 100vh;
    flex-direction: column;
    max-width: 550px;
    margin: 0 auto;

    .content-wrapper {
        background: $bg-color;
        padding: 0.15rem 0.25rem 1.2rem;

        .your-reson {
            font-size: 0.14rem;
            font-family: Helvetica;
            color: #666;
            line-height: 0.21rem;
        }

        .reson-box {
            width: 100%;
            background: #fff;
            border-radius: 0.2rem;
            margin: 0.15rem 0;

            .reason-item {
                padding: 0.15rem;
                border-bottom: 1px solid #ebebeb;
                display: flex;
                align-items: center;
                font-size: 0.14rem;
                font-family: Helvetica;
                color: $base-black;
                line-height: 0.18rem;

                &:last-child {
                    border-bottom: none;
                }

                img {
                    width: 0.12rem;
                    height: 0.12rem;
                    margin-right: 0.15rem;
                }
            }
        }

        .note {
            padding: 0.15rem;
            width: 100%;
            height: 1.9rem;
            border: none;
            border-radius: 0.2rem;
            box-sizing: border-box;
            font-family: Helvetica;
            font-size: 0.14rem;
            line-height: 0.21rem;
            color: $base-black;
            font-weight: 400;
            resize: none;
            caret-color: $primary-orange;

            &::placeholder {
                color: $light-gray;
            }
        }
    }

    .confirm-wrapper {
        position: fixed;
        width: 100vw;
        left: 0;
        right: 0;
        max-width: 550px;
        margin: 0 auto;
        bottom: 0;
        background: $bg-color;
        padding: 0.35rem 0.25rem 0.25rem;

        .btn {
            width: 100%;
            height: 0.6rem;
            line-height: 0.6rem;
            text-align: center;
            background: $primary-orange;
            border-radius: 0.08rem;
            font-size: 0.18rem;
            font-family: Helvetica-Bold, Helvetica;
            font-weight: bold;
            color: #fff;
            cursor: pointer;

            &-disabled {
                background: #ccc;
            }
        }
    }

    .confirm-shadow {
        box-shadow: 0 -0.15rem 0.3rem 0 $bg-color;
        z-index: 99;
    }
}
</style>
