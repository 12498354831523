var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "unsubscribe" }, [
    _c(
      "div",
      { staticClass: "content-wrapper" },
      [
        _c("div", { staticClass: "check-account" }, [
          _vm._v(
            "\n            " + _vm._s(_vm.getI18n("checkAccout")) + "\n        "
          ),
        ]),
        _vm._v(" "),
        _vm._l(_vm.getI18n("unLogOff"), function (item, index) {
          return _c("div", { key: index, staticClass: "logoff-item" }, [
            _c("span", [_vm._v(_vm._s(item))]),
          ])
        }),
        _vm._v(" "),
        _c(
          "div",
          { ref: "btnw", staticClass: "effects-box" },
          [
            _c("div", { staticClass: "effects-title" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.getI18n("effects")) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _vm._l(_vm.getI18n("effectsList"), function (item, index) {
              return _c("div", { key: index, staticClass: "effects-item" }, [
                _vm._v("\n                " + _vm._s(item) + "\n            "),
              ])
            }),
          ],
          2
        ),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "confirm-wrapper",
        class: { "confirm-shadow": _vm.isScroll },
      },
      [
        _c(
          "div",
          { staticClass: "confirm-btn", on: { click: _vm.handleApply } },
          [
            _vm._v(
              "\n            " + _vm._s(_vm.getI18n("apply")) + "\n        "
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }