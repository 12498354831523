<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-8-14 14:11:13 -->
<!-- @Last Modified by: wangwuhui -->
<!-- @Last Modified time: 2024-01-30 16:53:31 -->

<template>
    <div class="unsubscribe">
        <div class="content-wrapper">
            <div class="check-account">
                {{ getI18n('checkAccout') }}
            </div>
            <div
                v-for="(item, index) in getI18n('unLogOff')"
                :key="index"
                class="logoff-item"
            >
                <span>{{ item }}</span>
            </div>
            <div
                ref="btnw"
                class="effects-box"
            >
                <div class="effects-title">
                    {{ getI18n('effects') }}
                </div>
                <div
                    v-for="(item, index) in getI18n('effectsList')"
                    :key="index"
                    class="effects-item"
                >
                    {{ item }}
                </div>
            </div>
        </div>
        <div
            class="confirm-wrapper"
            :class="{'confirm-shadow': isScroll}"
        >
            <div
                class="confirm-btn"
                @click="handleApply"
            >
                {{ getI18n('apply') }}
            </div>
        </div>
    </div>
</template>

<script>
import {isWebview} from 'ssr-common/util/webview/ua';
import parseUa from 'ssr-common/vue/mixin/parse-ua';

import prefetch from 'easycash/common/mixin/server-prefetch';

import maxRem from 'olacred/common/mixin/max-rem';
import Unsubscribe from 'olacred/common/resource/unsubscribe.js';

export default {
    name: 'Unsubscribe',

    title() {
        return this.getI18n('title');
    },

    mixins: [prefetch('unsubscribe'), maxRem, parseUa()],

    data() {
        return {
            isScroll: false
        };
    },

    mounted() {
        this.$nextTick(() => {
            const sel = document.querySelector('.content-wrapper');
            this.isScroll = sel.scrollHeight > sel.clientHeight;
        });
    },

    methods: {
        getI18n(text, params) {
            return this.$t(`webview.unsubscribe.${text}`, params);
        },

        async initFetch({header}) {
            const res = await Promise.all([
                this.handleCanDelete(header),
                this.handleFetchUser(header)
            ]);

            return res.reduce((acc, val) => ({...acc, ...val}), {});
        },

        async handleCanDelete(header) {
            if (!isWebview(this.ua)) {
                return {deleteInfo: {allow: true}};
            }

            const {data: {body}} = await this.requestHelper(Unsubscribe.canDelete({headers: header}));

            return {deleteInfo: body ?? {}};
        },

        async handleFetchUser(header) {
            if (!isWebview(this.ua)) {
                return {userInfo: {}};
            }

            const {data: {body}} = await this.requestHelper(Unsubscribe.fetchUser({headers: header}));

            return {userInfo: body ?? {}};
        },

        handleApply() {
            if (this.unsubscribe?.deleteInfo?.allow) {
                let mobileNumber;
                if (this.$route.query.mobileNumber && this.$route.query.mobileNumber?.length >= 10) {
                    mobileNumber = this.$route.query.mobileNumber;
                } else {
                    mobileNumber = this.unsubscribe?.userInfo?.mobileNumber;
                }

                this.$router.push({
                    name: 'unsubscribeReason',
                    query: {mobileNumber}
                });

                return;
            }

            this.$toast(this.getI18n('notSatisfiable'));
        }
    }
};
</script>

<style lang="scss" scoped>
@font-face {
    font-family: "HelveticaBold";
    src: url("~olacred/common/style/font-face/Helvetica-Bold.otf");
}

@font-face {
    font-family: "Helvetica";
    src: url("~olacred/common/style/font-face/Helvetica.ttf");
}

.unsubscribe {
    padding: 0;
    flex-direction: column;
    max-width: 550px;
    margin: 0 auto;

    .content-wrapper {
        padding: 0.15rem 0.25rem 1.2rem;

        .check-account {
            font-size: 0.14rem;
            font-family: Helvetica;
            color: #666;
            line-height: 0.21rem;
        }

        .logoff-item {
            width: 100%;
            padding: 0.22rem 0.15rem;
            background: $bg-dark-color;
            border-radius: 0.08rem;
            margin-top: 0.1rem;

            &:first-child {
                margin-top: 0.15rem;
            }

            span {
                font-size: 0.14rem;
                font-family: Helvetica-Bold, Helvetica;
                font-weight: bold;
                color: $base-black;
                line-height: 0.18rem;
                display: flex;
                align-items: center;

                &::before {
                    content: "";
                    display: inline-block;
                    width: 0.02rem;
                    height: 0.02rem;
                    border-radius: 50%;
                    background-color: $base-black;
                    margin-right: 0.05rem;
                }
            }
        }

        .effects-box {
            margin-top: 0.16rem;
            background: #fff;
            border-radius: 0.2rem;
            padding: 0.2rem 0.15rem;

            .effects-title {
                font-size: 0.14rem;
                font-family: Helvetica-Bold, Helvetica;
                font-weight: bold;
                color: $base-black;
                line-height: 0.18rem;
                margin-bottom: 0.1rem;
            }

            .effects-item {
                font-size: 0.14rem;
                font-family: Helvetica;
                color: #666;
                line-height: 0.18rem;
            }
        }
    }

    .confirm-wrapper {
        position: fixed;
        width: 100%;
        left: 0;
        right: 0;
        max-width: 550px;
        margin: 0 auto;
        bottom: 0;
        background: $bg-color;
        padding: 0.35rem 0.25rem 0.25rem;

        .confirm-btn {
            width: 100%;
            height: 0.6rem;
            line-height: 0.6rem;
            text-align: center;
            background: $primary-orange;
            border-radius: 0.08rem;
            font-size: 0.18rem;
            font-family: Helvetica-Bold, Helvetica;
            font-weight: bold;
            color: #fff;
            cursor: pointer;
        }
    }
}
</style>
