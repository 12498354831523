var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "confirm" },
    [
      _c("div", { staticClass: "confirm-tip" }, [
        _vm._v("\n        " + _vm._s(_vm.getI18n("confirmTip")) + "\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "message-box" },
        [
          _c("yqg-md-preview", {
            staticClass: "rule-md",
            attrs: { "md-text": _vm.content },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "btn cancel-btn", on: { click: _vm.handleCancel } },
        [_vm._v("\n        " + _vm._s(_vm.getI18n("cancel")) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "btn confirm-btn",
          on: {
            click: function ($event) {
              _vm.visible = true
            },
          },
        },
        [_vm._v("\n        " + _vm._s(_vm.getI18n("confirm")) + "\n    ")]
      ),
      _vm._v(" "),
      _c(
        "ec-overlay",
        { staticClass: "logoff-modal", attrs: { visible: _vm.visible } },
        [
          _c("div", { staticClass: "modal-wrapper" }, [
            _c("div", { staticClass: "logoff-text" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.getI18n("logoff-text")) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "iKnow" }, [
              _c(
                "div",
                { staticClass: "confirm-off", on: { click: _vm.handleLogOut } },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.getI18n("confirmOff")) +
                      "\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "cancel-off",
                  on: {
                    click: function ($event) {
                      _vm.visible = false
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.getI18n("cancelOff")) +
                      "\n                "
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }