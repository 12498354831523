<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-8-17 16:34:45 -->
<!-- @Last Modified by: wangwuhui -->
<!-- @Last Modified time: 2024-01-30 16:53:30 -->

<template>
    <div class="confirm">
        <div class="confirm-tip">
            {{ getI18n('confirmTip') }}
        </div>
        <div class="message-box">
            <yqg-md-preview
                :md-text="content"
                class="rule-md"
            />
        </div>
        <div
            class="btn cancel-btn"
            @click="handleCancel"
        >
            {{ getI18n('cancel') }}
        </div>
        <div
            class="btn confirm-btn"
            @click="visible = true"
        >
            {{ getI18n('confirm') }}
        </div>

        <ec-overlay
            :visible="visible"
            class="logoff-modal"
        >
            <div class="modal-wrapper">
                <div class="logoff-text">
                    {{ getI18n('logoff-text') }}
                </div>
                <div class="iKnow">
                    <div
                        class="confirm-off"
                        @click="handleLogOut"
                    >
                        {{ getI18n('confirmOff') }}
                    </div>
                    <div
                        class="cancel-off"
                        @click="visible = false"
                    >
                        {{ getI18n('cancelOff') }}
                    </div>
                </div>
            </div>
        </ec-overlay>
    </div>
</template>

<script>
import {debounce} from 'lodash';

import ua from 'ssr-common/util/webview/ua-parsed';
import WebviewInterface from 'ssr-common/util/webview/yqg-webview-interface';

import EcOverlay from 'easycash/common/component/ec-overlay';
import Webview from 'easycash/common/resource/Webview';
import Mustache from 'easycash/common/util/mustache';

import maxRem from 'olacred/common/mixin/max-rem';
import Unsubscribe from 'olacred/common/resource/unsubscribe.js';

export default {
    name: 'Confirm',

    title() {
        return this.getI18n('title');
    },

    components: {EcOverlay},

    mixins: [maxRem],

    data() {
        return {
            visible: false,
            content: '',
            isRequesting: false,
        };
    },

    mounted() {
        this.getAgreement();
    },

    methods: {
        getI18n(text, params) {
            return this.$t(`webview.unsubscribe.${text}`, params);
        },

        async getAgreement() {
            const {data: {body}} = await Webview.getAgreementByKey({key: 'HAAB_USER_DELETE'});
            if (body) {
                const res = this.dealBody(body);
                this.content = res[0].content;
            }
        },

        dealBody(body) {
            const originBody = body?.agreementList ?? [body];

            return originBody.map(item => {
                const {pageTitle: title, values, nameList, imageUrlList, displayTitle} = item;
                let {content} = item;
                if (values) {
                    content = Mustache.render(content, {
                        ...values,
                        NAME_LIST: nameList,
                        IMAGE_URL_LIST: imageUrlList
                    });
                }

                return {content, title, displayTitle};
            });
        },

        handleCancel() {
            if (!ua.isWebview) {
                this.$router.go(-4);

                return;
            }

            WebviewInterface.openHomePage();
        },

        handleLogOut: debounce(async function () {
            if (this.isRequesting) {
                return;
            }

            const data = JSON.parse(window.localStorage.getItem('reasonList') || '{}');
            this.isRequesting = true;
            if (!ua.isWebview) {
                try {
                    const {data: {body}} = await Unsubscribe.deleteApplyWithOutLogin(data);
                    if (body?.deleteStatus) {
                        this.$router.go(-4);
                    }

                    this.isRequesting = false;
                } catch (error) {
                    this.isRequesting = false;
                }

                return;
            }

            try {
                const {data: {body}} = await Unsubscribe.deleteApply(data).catch(err => err);
                if (body?.deleteStatus) {
                    WebviewInterface.callHandler('userLogOut');
                }

                this.isRequesting = false;
            } catch (error) {
                this.isRequesting = false;
            }
        }, 300)
    }
};
</script>

<style lang="scss" scoped>
@font-face {
    font-family: "HelveticaBold";
    src: url("~olacred/common/style/font-face/Helvetica-Bold.otf");
}

@font-face {
    font-family: "Helvetica";
    src: url("~olacred/common/style/font-face/Helvetica.ttf");
}

.confirm {
    padding: 0.15rem 0.25rem 0.25rem;
    overflow-y: auto;
    max-width: 550px;
    margin: 0 auto;

    &-tip {
        font-size: 0.14rem;
        font-family: Helvetica;
        color: #666;
        line-height: 0.21rem;
        margin-bottom: 0.15rem;
    }

    .message-box {
        padding: 0.1rem 0.15rem;
        background: #fff;
        border-radius: 0.2rem;
        margin-bottom: 0.2rem;

        .rule-md {
            font-size: 0.14rem;
            line-height: 0.23rem;
        }
    }

    .btn {
        width: 3.25rem;
        height: 0.6rem;
        line-height: 0.6rem;
        font-size: 0.18rem;
        text-align: center;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
        border-radius: 0.08rem;
        margin: 0 auto;
        cursor: pointer;
    }

    .cancel-btn {
        background: $primary-orange;
        margin-bottom: 0.1rem;
        color: #fff;
    }

    .confirm-btn {
        border: 1px solid $primary-orange;
        color: $primary-orange;
    }

    .logoff-modal {
        z-index: 99;

        .modal-wrapper {
            width: 80vw;
            padding: 0.27rem 0 0;
            position: relative;
            box-sizing: border-box;
            background-color: #fff;
            border-radius: 0.08rem;
            text-align: center;
            max-width: calc(550px - 0.32rem);

            .logoff-text {
                padding: 0 0.2rem 0.27rem;
                font-size: 0.14rem;
                font-family: Helvetica;
                color: $base-black;
                line-height: 0.18rem;
                text-align: center;
                border-bottom: 1px solid #ebebeb;
            }

            .iKnow {
                padding: 0.18rem;
                text-align: center;
                font-size: 0.18rem;
                font-family: Helvetica-Bold, Helvetica;
                font-weight: bold;
                display: flex;
                align-items: center;

                .confirm-off {
                    width: 50%;
                    color: #a3acbc;
                    cursor: pointer;
                }

                .cancel-off {
                    width: 50%;
                    color: $primary-orange;
                    cursor: pointer;
                }
            }
        }
    }
}
</style>
