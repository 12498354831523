var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "unsubscribeReson", attrs: { id: "unsubscribeReson" } },
    [
      _c("div", { ref: "btnw", staticClass: "content-wrapper" }, [
        _c("div", { staticClass: "your-reson" }, [
          _vm._v(
            "\n            " + _vm._s(_vm.getI18n("yourReson")) + "\n        "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "reson-box" },
          _vm._l(_vm.reasonList, function (item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "reason-item",
                on: {
                  click: function ($event) {
                    item.checked = !item.checked
                  },
                },
              },
              [
                _c("img", {
                  attrs: { src: item.checked ? _vm.selected : _vm.notSelected },
                }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(item.label))]),
              ]
            )
          }),
          0
        ),
        _vm._v(" "),
        _c("div", { staticClass: "text-wrap" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.note,
                expression: "note",
              },
            ],
            ref: "note",
            staticClass: "note",
            attrs: {
              maxlength: "3000",
              placeholder: _vm.getI18n("detailReson"),
            },
            domProps: { value: _vm.note },
            on: {
              focus: function ($event) {
                return _vm.onKeyBoardCahnge("focus")
              },
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.note = $event.target.value
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "confirm-wrapper" }, [
        _c(
          "div",
          {
            staticClass: "btn",
            class: { "btn-disabled": !_vm.checkedList.length },
            on: { click: _vm.handleReason },
          },
          [
            _vm._v(
              "\n            " + _vm._s(_vm.getI18n("apply")) + "\n        "
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }